@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300;400;900&display=swap');

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Lato', sans-serif;
  font-weight: 300;
}

.nav-bar {
  position: fixed;
  width: 100%;
  color: #202020;
  background-color: #FFF;
  height: 65px;
  line-height: 65px;
  box-shadow: 0 8px 6px -6px gray;
}

.nav-bar h1 {
  font-weight: 400;
  float: left;
  margin-left: 20%;
}

.nav-bar h1 a:hover {
  cursor: pointer;
}

.nav-bar h2 {
  font-size: 16px;
  float: right;
  margin-left: 15px;
}

.nav-bar h2 a:hover {
  color: gray;
  cursor: pointer;
}

.nav-bar a:focus, .nav-bar a:visited, .nav-bar a:link, .nav-bar a:active {
  color: black;
  text-decoration: none;
}

.nav-bar #last {
  margin-right: 20%;
}

#home {
  color: #202020;
  text-align: center;
}

#home {
  padding-top: 100px;
}

#home h1 {
  font-weight: 900;
}

#home span {
  font-weight: 900;
  font-size: 18px;
}

#home h3 {
  padding-top: 10px;
  padding-bottom: 10px;
}

#home a {
  padding: 5px 5px;
}

#home a:focus, #home a:hover, #home a:visited, #home a:link, #home a:active {
  color: black;
}

#home p {
  margin: 20px 20%;
  font-weight: 400;
}

#experience, #projects, #photos {
  margin: 10px 20% 10px 20%;
}

#experience h1, #projects h1, #photos h1 {
  padding-top: 75px;
  margin-left: 5%;
  font-weight: 400;
}

#projects a:focus, #projects a:hover, #projects a:visited, #projects a:link, #projects a:active {
  color: black;
}

#projects svg {
  transition: transform .2s;
}

#projects svg:hover {
  transform: scale(1.2);
}

#photos p {
  margin: 0% 15%;
  margin-top: 2%;
  text-align: center;
}

.slider {
  position: relative;
  height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.slider img {
  width: auto;
  max-height: 350px;
  border-radius: 10px;
}

.right-arrow {
  position: absolute;
  top: 50%;
  right: 5%;
  font-size: 3;
  color: #000;
  cursor: pointer;
}

.left-arrow {
  position: absolute;
  top: 50%;
  left: 5%;
  font-size: 3;
  color: #000;
  cursor: pointer;
}

.slide {
  opacity: 0;
  transition-duration: 0.5s ease;
}

.slide.active {
  opacity: 1;
  transition-duration: 0.5s;
  transform: scale(1.08);
}

.card {
  margin: 3% 5%;
  box-shadow: 0 2px 2px rgba(0,0,0,0.12), 
              0 4px 4px rgba(0,0,0,0.12), 
              0 8px 8px rgba(0,0,0,0.12);
  height: 150px;
  max-width: 685px;
  border-radius: 5px;
}

.card table {
    border-collapse: collapse;
}

.card img {
  border-radius: 5px 0px 0px 5px;
}

.card-element h2, .card-element h4 {
  display: inline-block;
}

.card-element h2 {
  margin-left: 8px;
  margin-top: -35px;
  font-weight: 400;
}

.card-element h3 {
  margin-left: 8px;
  margin-bottom: 25px;
}

.card-element p {
  margin-left: 8px;
  margin-right: 8px;
}

.card-element h4 {
  margin-right: 8px;
  float: right;
}

footer {
  text-align: center;
  margin: 15px 0px;
  font-size: 12px;
}

@media only screen and (max-width: 1000px) {
  .nav-bar h1 {
    font-weight: bold;
    float: left;
    margin-left: 5%;
  }

  .nav-bar h2 {
    display: none;
  }

  #home p {
    margin: 20px 5%;
  }

  #experience, #projects, #photos {
    margin: 10px 5%;
  }

  #experience h1, #projects h1, #photos h1 {
    padding-top: 50px;
  }

  #projects svg:hover {
    transform: scale(1);
  }

  .slider img {
    max-height: 250px;
  }

  .card {
    font-size: smaller;
    margin: 3% 2%;
  }
}

@media only screen and (max-width: 650px) {
  #experience h4 {
    display: none;
  }

}

@media only screen and (max-width: 530px) {
  .slider img {
    max-height: 200px;
  }

  #photos p {
    margin-bottom: -75px;
  }
}

@media only screen and (max-width: 500px) {
  #home h3 {
    font-size: 16px;
  }

  #experience h1, #projects h1, #photos h1 {
    margin-left: 0;
    text-align: center;
  }
  
  .card h3 {
    margin-bottom: 10px;
  }

  .card h4 {
    display: none;
  }

  .card p {
    font-size: x-small;
  }

  .slider {
    width: 100%;
  }

  .left-arrow {
    left: 0%;
  }

  .right-arrow {
    right: 0%;
  }
}

@media only screen and (max-width: 500px) {
  .card {
    font-size: x-small;
  }
  .card p {
    display: none;
  }

  .slider img {
    max-height: 150px;
  }
}

@media only screen and (min-width: 1300px) {
  .nav-bar h2 {
    display: none;
  }
  
  #experience h1, #projects h1, #photos h1 {
    text-align: center;
    margin-left: 0%;
  }

  #photos p {
    margin: 0px 0px;
  }

  .card {
    width: 685px;
    margin-left: auto;
    margin-right: auto;
  }
}